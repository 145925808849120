import React, { useState, useEffect } from "react";

const artworksData = [
	{
		id: 1,
		imageUrl: "img/recognition1.webp",
	    },
	    {
		id: 2,
		imageUrl: "img/recognition2.webp",
	    },
];
const artworksData2 = [
	{
	  id: 1,
	  imageUrl: "img/ifsmilecapture.webp",
	},
	{
	  id: 2,
	  imageUrl: "img/ifsmilecapture2.webp",
	},
];
const artworksData3 = [
	{
		id: 1,
		imageUrl: "img/hackognize1.webp",
	    },
	    {
		id: 2,
		imageUrl: "img/hackognize2.webp",
	    },
];
function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
	nextSlide();
    }, 5000);

    return () => {
	clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };

  return (
    <div className="slideshow-container">
	<br />
	<h2 className="title">reinterpret(system) &#123; through misuse &#125;;</h2>
	<br /><br />
	<div className="artworkscontainer">
	<p className="artworksdetail">
	近年，テクノロジーの発展により選択肢が増え，深く考えることなく選択することができる時代となった．
	このような背景の中で，本作品郡はシステムの不可知な部分に意識を向けさせることを⽬的とする．
	特に，技術的ブラックボックスとして知られる既製品のシステムに対して，内部から⼲渉することが難しい状況では，
	創造的誤⽤を通じてその不可知性を明らかにできると考える．創造的誤⽤をしたシステムを利⽤し，表現することで，
	単⼀の視点や既成概念にとらわれず，深い洞察や批判的思考を促し，テクノロジーの裏側を多⾓的に捉えられる．
	本作品群ではその有効性を検証し，ユーザーがシステムに⼲渉し，創作⾏為を⾏うための⽅法を模索した．
      </p>
	<hr></hr>
	<p className="artworksdetailen">
		In recent years, the development of technology has increased the number of choices available, 
		and we have entered an era where we can make choices without thinking deeply.
		Against this background, this series of works aims to focus on the unknowable parts of the system.
		In particular, for ready-made systems known as technical black boxes, it is difficult to intervene from the inside,
		but it is believed that their unknowability can be revealed through creative misuse.
		By using a system that has been creatively misused to express itself, it is possible to avoid being bound by 
		a single perspective or established concepts,
		and to promote deep insight and critical thinking, enabling a multifaceted view of the technology behind the scenes.
		In this series of works, the effectiveness of this approach was verified, 
		and methods were explored for users to intervene in the system and engage in creative acts.
	</p>
	</div>
	<br /><br />
	<br />
	<div className="slideshow">
	  {artworksData.map((artwork, index) => (
	    <div
		key={artwork.id}
		className={`slide ${index === currentSlide ? "active" : ""}`}
	    >
		<img
		  src={artwork.imageUrl}
		  alt="Artwork"
		  className="artworksimg-technoandon"
		/>
	    </div>
	  ))}
	</div>
	<h2 className="artworkstitle">Recognition: capture(scene)</h2>
	<br />
	<br />
	<div className="artworkscontainer">
	<p className="artworksdetail">
          パノラマ写真は、1840年代に考案されたスイングレンズ方式のカメラを起源とし、スマートフォンの普及に伴い、現在では身近なものとなった。
          通常のパノラマ写真は、人がカメラを手で動かしながら撮影し、細長い1枚の画像を生成する。しかし、この作品ではiPhoneを固定したまま撮影している。
          iPhoneの前にあるディスプレイでは画像が単に右から左に流れているだけだが、iPhone越しに見るとパノラマ写真が撮影されている。撮影後に表示される結果は、
          鑑賞者が実際に見ているものとパノラマ画像との間に「ずれ」を生じさせる。
          iPhoneには特別な技術的操作は加えておらず、ディスプレイ上に再生される映像は、iOS特有の撮影アルゴリズムとスリットスキャンを応用し、
          スキャンのタイミングを調整することで撮影が可能になっている。この作品は、鑑賞者が普段何気なく使っている機能を誤用し、作品を通じて新たな視覚体験やものの見方を促す試みである。
      </p>
	  <br />
	  <hr></hr>
	  <br />
	  <p className="artworksdetailen">
          Panoramic photography originated from the swing lens camera invented
          in the 1840s, and has become familiar today with the spread of
          smartphones. A normal panoramic photograph is taken by moving the
          camera by hand, generating a single long and narrow image. However, in
          this work, the iPhone is fixed in place. On the display in front of
          the iPhone, the image simply flows from right to left, but when viewed
          through the iPhone, a panoramic photograph is taken. The result
          displayed after shooting creates a "gap" between what the viewer
          actually sees and the panoramic image. No special technical operations
          were performed on the iPhone, and the image played on the display was
          taken by applying iOS-specific shooting algorithms and slit scanning,
          and adjusting the timing of the scan. This work is an attempt to
          misuse functions that viewers usually use casually, and encourage new
          visual experiences and ways of looking at things through the work.
        </p>
	  <hr></hr>
	  <br />
	  <br />
	  <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/jZeSh5alXvM?si=o8OYX7RXchYblfUO"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-med
        ia; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
	  {/* <iframe
	    width="350px"
	    height="500"
	    src="https://www.youtube.com/embed/jZeSh5alXvM?si=o8OYX7RXchYblfUO"
	    title="YouTube video player"
	    frameborder="0"
	    allow="accelerometer; autoplay; clipboard-write; encrypted-med
	  ia; gyroscope; picture-in-picture; web-share"
	    allowfullscreen
	    className="responsive-iframe"
	  ></iframe> */}
	  <p className="credit">
	    Credit:
	    <br />
	    <a href="/">Rintaro Fujita(Creator)</a>
	    <br />
	    <a href="https://scottallen.ws/biography">Scott Allen(Advisor)</a>
	    <br />
	  </p>
	  <br />
	  {/* <section>
	    <h2 className="section">Conference / Exhibition </h2>
	    <ul>
		<li>
		  <a
		    href="https://asia.siggraph.org/2024/presentation/?id=artg_248&sess=sess183"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    SIGGRAPH Asia 2024 Art Gallery
		  </a>
		</li>
		<br/>
		<li>
		  <a
		    href="https://hack.wired.jp/finalists/index.html"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    WIRED CREATIVE HACK AWARD 2024 Finalist
		  </a>
		</li>
		<br/>
		<li>
		<a
		  href="https://peatix.com/event/4210013"
		  target="_blank"
		  rel="noopener noreferrer"
		>
		  HOMEWORKS 2024
		</a>
	    </li>
	    </ul>
	  </section> */}
	  <br />
	  <br />
	</div>
	<div className="slideshow">
	  {artworksData2.map((artwork, index) => (
	    <div
		key={artwork.id}
		className={`slide ${index === currentSlide ? "active" : ""}`}
	    >
		<img
		  src={artwork.imageUrl}
		  alt="Artwork"
		  className="artworksimg-technoandon"
		/>
	    </div>
	  ))}
	</div>
	<h2 className="artworkstitle">if (smile) &#123; capture(); &#125;</h2>
	<div className="artworkscontainer">
	  <p className="artworksdetail">
	  カメラの歴史において，撮影の瞬間は技術的にも芸術的にも重要な意味を持つ．
	  従来のカメラでは，シャッターを切ること自体が意図的な行動であり，撮影者の選択とタイミングに依存していた．
	  しかし，デジタルカメラの進化により，撮影行為は自動化にも対応し，そのプロセスにおいて人間の判断が必要ない撮影が可能になった．
	  スマイルシャッターの創造的誤用を通じて，カメラの内部で何が行われているのか，その隠れた処理の一端に触れ，カメラが見落としてきた可能性や，
	  カメラという機器に対する再解釈を促す．
	  </p>
	  <br />
	  <hr></hr>
	  <br />
	  <p className="artworksdetailen">
	  In the history of the camera, the moment of taking a picture is of great technical and artistic significance.
	  <br/>With conventional cameras, releasing the shutter itself was a deliberate act, dependent on the choice and timing of the photographer.
	  <br/>However, with the evolution of digital cameras, the act of photographing can now be automated, making it possible to take pictures without the need for human judgment in the process.
	  <br/>Through the creative misuse of the smiley shutter, we can touch upon what is going on inside the camera and some of its hidden processes, and we can see the possibilities that have been overlooked by the camera, as well as reinterpret the camera as a device.
	  <br/>It also encourages us to reinterpret the camera as an instrument.
	  </p>
	  <br />
	  <br />
	  <iframe width="350" height="500" src="https://www.youtube.com/embed/MJ2C1aNkvAc?si=unbiW_P1kXx6LIXU" 
	  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
	  encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
	  allowfullscreen
	  className="responsive-iframe"></iframe>
	  {/* <iframe
	    width="350px"
	    height="500"
	    src="https://www.youtube.com/embed/jZeSh5alXvM?si=o8OYX7RXchYblfUO"
	    title="YouTube video player"
	    frameborder="0"
	    allow="accelerometer; autoplay; clipboard-write; encrypted-med
	  ia; gyroscope; picture-in-picture; web-share"
	    allowfullscreen
	    className="responsive-iframe"
	  ></iframe> */}
	  <p className="credit">
	    Credit:
	    <br />
	    <a href="/">Rintaro Fujita(Creator)</a>
	    <br />
	    <a href="https://scottallen.ws/biography">Scott Allen(Advisor)</a>
	    <br />
	  </p>
	  <br />
	  {/* <section>
	    <h2 className="section">Conference / Exhibition </h2>
	    <ul>
		<li>
		  <a
		    href="https://asia.siggraph.org/2024/presentation/?id=artg_248&sess=sess183"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    SIGGRAPH Asia 2024 Art Gallery
		  </a>
		</li>
		<br/>
		<li>
		  <a
		    href="https://hack.wired.jp/finalists/index.html"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    WIRED CREATIVE HACK AWARD 2024 Finalist
		  </a>
		</li>
		<br/>
		<li>
		<a
		  href="https://peatix.com/event/4210013"
		  target="_blank"
		  rel="noopener noreferrer"
		>
		  HOMEWORKS 2024
		</a>
	    </li>
	    </ul>
	  </section> */}
	  <br />
	  <br />
	</div>
	<div className="slideshow">
	  {artworksData3.map((artwork, index) => (
	    <div
		key={artwork.id}
		className={`slide ${index === currentSlide ? "active" : ""}`}
	    >
		<img
		  src={artwork.imageUrl}
		  alt="Artwork"
		  className="artworksimg-technoandon"
		/>
	    </div>
	  ))}
	</div>
	<h2 className="artworkstitle">sound: affect(object)</h2>
	<div className="artworkscontainer">
	<p className="artworksdetail">
          本作品は言葉と音の複雑な関係性に焦点を当て，音声認識システムの誤認識を利用したサウンド・インスタレーション作品です．
          本作品に配置されている音声認識アシスタントのSiriは空間に配置されたスピーカーから流れる音に反応します．
          音声認識システムはあくまで与えられた音を元に判断し，返答しますが，それらは作者が意図的に音声認識システムが誤認識するように選んだ音であるため，
          システムは読み間違えて食い違っていきます．
          作品の体験を通して，鑑賞者は本来の言葉の意味や関係性などを再考することになります．
        </p>
	  <br />
	  <hr></hr>
	  <br />
	  <p className="artworksdetailen">
          Focusing on the complex relationship between words and sound, this
          work is a sound installation utilizing the misrecognition of a voice
          recognition system. Siri, the voice recognition assistant installed in
          this work, responds to sounds played from speakers placed in the
          space. The voice recognition system makes judgments and replies based
          on the given sounds, but because these are sounds that the author
          intentionally chose for the voice recognition system to misrecognize,
          the system misreads and misinterprets the sounds. The system misreads
          and responds differently. Through the experience of the work, the
          viewer is encouraged to reconsider the meaning of the original words
          and their relationship to each other.
        </p>
	  <br />
	  <br />
	  <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/zflnMjwD9-0?si=Z4DOX-g5rG5BOoQi"
          title="YouTube vid
        eo player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
        <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/KZXdeZvYSzg?si=sZmzwwkv0KLD9-SJ"
          title="YouTube vid
        eo player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
	  {/* <iframe
	    width="350px"
	    height="500"
	    src="https://www.youtube.com/embed/jZeSh5alXvM?si=o8OYX7RXchYblfUO"
	    title="YouTube video player"
	    frameborder="0"
	    allow="accelerometer; autoplay; clipboard-write; encrypted-med
	  ia; gyroscope; picture-in-picture; web-share"
	    allowfullscreen
	    className="responsive-iframe"
	  ></iframe> */}
	  <p className="credit">
	    Credit:
	    <br />
	    <a href="/">Rintaro Fujita(Creator)</a>
	    <br />
	    <a href="https://scottallen.ws/biography">Scott Allen(Advisor)</a>
	    <br />
	  </p>
	  <br />
	  {/* <section>
	    <h2 className="section">Conference / Exhibition </h2>
	    <ul>
		<li>
		  <a
		    href="https://asia.siggraph.org/2024/presentation/?id=artg_248&sess=sess183"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    SIGGRAPH Asia 2024 Art Gallery
		  </a>
		</li>
		<br/>
		<li>
		  <a
		    href="https://hack.wired.jp/finalists/index.html"
		    target="_blank"
		    rel="noopener noreferrer"
		  >
		    WIRED CREATIVE HACK AWARD 2024 Finalist
		  </a>
		</li>
		<br/>
		<li>
		<a
		  href="https://peatix.com/event/4210013"
		  target="_blank"
		  rel="noopener noreferrer"
		>
		  HOMEWORKS 2024
		</a>
	    </li>
	    </ul>
	  </section> */}
	  <br />
	  <br />
	</div>
	<br />
	<br />
	<br />
    </div>
  );
}

export default Decieve;
